






















































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { GetCategory, GetBiaozhuyuan } from "@/request/mark";
import { GetZhuantis } from "@/request/schema";
@Component({})
export default class Name extends Vue {
  @Prop()
  private data: any;
  @Prop({ type: Boolean, default: false })
  private ifShowZhuanti: any;
  @Prop({ type: Boolean, default: false })
  private ifShowFenlei: any;
  @Prop({ type: Boolean, default: false })
  private ifShowBiaozhuyuan: any;
  @Prop({ type: Boolean, default: false })
  private ifShowFenpeizhuangtai: any;
  @Prop({ type: Boolean, default: false })
  private ifShowBiaozhuzhuangtai: any;
  @Prop({ type: Boolean, default: false })
  private ifShowInput: any;
  @Prop({ type: Boolean, default: false })
  private ifShowShenhezhuangtai: any;
  @Prop({ type: String, default: "请输入" })
  private inputText: any;

  private fenleis: any = [];
  private biaozhuyuan: any = [];
  private fenpeiStatus: any = ["待分配", "已分配"];
  private shenheStatus: any = ["待审核", "已打回", "已通过"];
  private filters: any = {};
  private biaozhuStatus: any = [
    "待标注",
    "标注中",
    "待审核",
    "已打回",
    "已通过",
  ];
  @Watch("data", { immediate: true })
  private dataChange() {
    this.filters = JSON.parse(JSON.stringify(this.data));
  }
  private leixings: any = [];
  private search() {
    this.filters.current_page = 1;
    this.$emit("changeSearch", this.filters);
  }
  private fenleiFocus() {
    const params: any = {};
    GetCategory(this, params).then((res: any) => {
      this.fenleis = res;
    });
  }
  private leixingFocus() {
    GetZhuantis(this).then((res: any) => {
      this.leixings = res;
    });
  }
  private biaozhuyuanFocus() {
    const params: any = {};
    GetBiaozhuyuan(this, params).then((res: any) => {
      this.biaozhuyuan = res;
    });
  }
  private mounted() {
    if (this.ifShowBiaozhuyuan) {
      this.biaozhuyuanFocus();
    }
    if (this.ifShowFenlei) {
      this.fenleiFocus();
    }
  }
}
